@import "../../../../scss/index";

.list-users {
  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    &-switch{
      display: flex;
      align-items: center;
      > button {
        margin-right: 20px;
      }
    }
  }
  .users-active, .users-inactive {
    background-color: #FFF;
    padding: 10px 20px;

    .ant-list-item-meta{
      display: flex;
      align-items: center;
    }
  }
}
@import "../../../scss/index";

.login-form {
  .ant-form-item:last-of-type {
    margin: 0;
  }
  &__input {
    .ant-input-prefix {
      i {
        font-size: 0em +22px / $defaultFontSize;
      }
    }
    input {
      font-size: 0em + 20px / $defaultFontSize;
      &:focus{
        border-color: $primary-color;
        box-shadow: none;
      }
    }
  }

  &__button {
    width: 100%;
    font-size: 0em + 22px / $defaultFontSize;
    background-color: $primary-color;
    color: $font-light !important;
    height: 42px;

    &:hover, &:focus, &:active {
      background-color: $primary-color-hover;
    }
  }
}
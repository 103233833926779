@import "../../../scss/index";
.web-menu-top {
    display: flex;
    top: 0;
    background-color: $background-dark-web;
    border: 0;
    align-items: center;
    &__left {
        &-logo {
          width: 200px;
          padding: 10px;
        }
    }
    li {
        border: none !important;
        a {
            color: $font-light !important;
            font-weight: bold;
            &:hover {
                color: $primary-color !important;
            }
        }
    }
}
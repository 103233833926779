@import "../../../scss/index";

.sign-in {
  background-image: url("../../../assets/img/jpg/background-v1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    &-logo {
      padding: 60px 20px;

      img {
        width: 100%;
      }
    }

    &-tabs {
      .ant-tabs-nav-wrap {
        background-color: $background-light;
      }
      .ant-tabs {
        width: 90vw;
        margin-bottom: 60px;
        
        @media (min-width: $media-breackpoint-up-lg) {
          width: auto;
        }
        .ant-tabs-nav {
          .ant-tabs-nav-wrap{
            .ant-tabs-nav-list {
              .ant-tabs-tab-active {
                border-top: 4px solid $primary-color !important;
                &:hover {
                  color: $primary-color-hover;
                }
              }
            }
          }
        }
      }
      .ant-tabs-card .ant-tabs-tab {
        border-color: transparent;
        background-color: $background-light;
        font-size: 0em + 19px / $defaultFontSize;
        border-top: 4px solid $border-grey !important;
        height: 60px !important;
        @media (min-width: $media-breackpoint-up-lg) {
          font-size: 0em + 23px / $defaultFontSize;
        }
        &:hover {
          color: $font-dark;
        }
      }
      .ant-tabs-card .ant-tabs-content {
        margin-top: -16px;
      }
      .ant-tabs-card {
        .ant-tabs-tabpane {
          background: #fff;
          padding: 16px;
        }
        .ant-tabs-nav::before {
          display: none;
        }
      }
    }
  }
}
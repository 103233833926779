@import "../../../scss/index.scss";
.footer {
    background-color: $background-dark-web;
    border-top: 3px solid $primary-color;

    &__copyright {
        margin-top: 30px;
        .ant-col {
            color: $primary-color-dark;
            &:last-of-type {
                text-align: right;
            }
        }
    }
    @media (max-width: 600px) {
        &__copyright {
            .ant-col {
                &:last-of-type {
                    text-align: left;
                }
            }
        }
    }
}
@import "../../../scss/index";
.social-links {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    width: 100% !important;
    a{
        display: flex !important;
        margin-right: 10px !important;
        padding: 5px 8px !important;
        border-radius: 5px !important;
        &.youtube {
            background-color: $youtube !important;
        }
        &.twitter {
            background-color: $twitter !important;
        }
        &.facebook {
            background-color: $facebook !important;
        }
        &.linkedin {
            background-color: $linkedin !important;
        }
        &.github {
            background-color: $github !important;
        }
        &.instagram{
            background: #f09433; 
            background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
            background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
            background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
        }
        svg {
            width: 15px !important;
            height: 20px !important;
        }
    }
}

@media (max-width: 600px) {
    .web-menu-top>.social-links,ul>.social-links {
        display: none !important;
    }
}
@import "../../../../scss/index";
.menu-web-list{
    &__header {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
    }
    &__items{
        background-color: #fff;
        padding: 10px 20px;
        .List{
            position: relative;
            .draggable {
                width: 100%;
                background-color: rgba(255, 255, 255, 0.5);

                &:hover{
                    cursor: pointer;
                }

                &.dragged {
                    border: solid 1px #E2E2E2;
                    padding: 0 10px;
                }
            }
        }
        &.ant-list-item-meta{
            display: flex;
            align-items: center;
        }
    }
}
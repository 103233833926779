@import "../../../scss/index.scss";
.main-banner{
    position: relative;
    height: 500px;
    background-image: url("../../../assets/img/jpg/banner.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &__dark {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $background-dark;
        opacity: 0.7;
    }

    .ant-row {
        display: flex;
        align-items: center;
        height: 100%;

        h1 {
            color: $font-light;
            font-size: 60px;
            font-weight: bold;
            line-height: 1;
        }

        h2 {
            color: $font-light;
            font-size: 24px;
        }

        @media (max-width: 600px) {
            h1 {
                font-size: 45px;
                padding-left: 15px;
            }
            h2 {
                font-size: 18px;
                padding-left: 15px;
            }
        }
    }
}
.ant-menu-submenu-title {
    color: #fff !important;
}